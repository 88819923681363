import React, { PureComponent, useState, useCallback, useEffect } from "react";
import Link from "../components/LinkWithTransitionLock"
import Helmet from "react-helmet"
import InViewObserver from "../components/InViewObserver"

import { Expo, gsap } from 'gsap';

class Page extends PureComponent {
  constructor(props) {
     super(props);
  }
  
  componentDidMount() {

    InViewObserver()

  }

  componentWillUnmount() {}

  render() {
    return (

      <div className="content_wrap">

        <div className="page_header">
          <h2 className="sub_title">駐村編輯</h2>
          <h1 className="main_title">職人黃頁</h1>
          <h6 className="speaker">策展人/林書豪</h6>
        </div>

        <div className="page_content">

          <section className="row_area_s project_c_2_a">
            <div className="column_4">
              <div className="context">
                <h6>藉由田野調查認識社區，累積文字人脈並且不斷擴充在地黃頁資料庫， 並且將之裝置化，促進社區的交流與內聚力。</h6>
              </div>
            </div>
          </section>

          <section className="row_area_s project_c_2_b">
            <div className="persona_wrap">

              <div className="persona_item">
                <div className="col_content row_area_ms">
                  <div className="title_group">
                    <h6 className="name">Eve</h6>
                    <h5 className="title">家庭煮婦</h5>
                  </div>
                  <div className="col_1 col_a">
                    <div className="context">
                      <h6 className="item_title">相關背景</h6>
                      <p>活動企劃/執行<br />產品開發<br />專案管理</p>
                    </div>
                    <div className="context">
                      <h6 className="item_title">專長</h6>
                      <p>溝通、閒聊。<br />看到有人散播假消息，會去糾正，喜歡轉發正確資訊</p>
                    </div>
                    <div className="context">
                      <h6 className="item_title">興趣</h6>
                      <p>閱讀、看電影、帶小孩</p>
                    </div>
                    <div className="context">
                      <h6 className="item_title">關心議題</h6>
                      <p>社會、人文、下一代、民族認同</p>
                    </div>
                    <div className="context">
                      <h6 className="item_title">習慣與人分享</h6>
                      <p>食物、網路資訊、物資、信仰</p>
                    </div>
                  </div>
                  <div className="col_1 col_b">
                    <div className="context">
                      <h6 className="item_title">公共藝術與我的關係</h6>
                      <p>藝術能像空氣一樣，自然的存在生活各細節中</p>
                    </div>
                    <div className="context">
                      <h6 className="item_title">對我來說「家/家園」是什麼</h6>
                      <p>共榮、互助互信，如同大家庭</p>
                    </div>
                    <div className="context">
                      <h6 className="item_title">我所關心的：世代如何正確傳承？</h6>
                      <p>認識自我、自我定位、勇於行動，才有能力抵擋外面的攻擊。<br />了解－面對－解決</p>
                    </div>
                  </div>
                </div>
                <div className="col_person_visual">
                  <img src={require("../images/project_c/c_2/1.png")} alt="Eve 家庭煮婦"/>
                </div>
              </div>

              <div className="persona_item">
                <div className="col_content row_area_ms">
                  <div className="title_group">
                    <h6 className="name">Joyce</h6>
                    <h5 className="title">創作者</h5>
                  </div>
                  <div className="col_1 col_a">
                    <div className="context">
                      <h6 className="item_title">相關背景</h6>
                      <p>台中人<br />念心理學，研究所是工業設計/建築相關<br />都市活動企劃專員<br />寶藏巖駐村專員<br />走在創作者之路</p>
                    </div>
                    <div className="context">
                      <h6 className="item_title">專長</h6>
                      <p>創意行動策展/企劃、文案書寫/平面設計、社區工作</p>
                    </div>
                    <div className="context">
                      <h6 className="item_title">興趣</h6>
                      <p>探索新地方、閱讀文學/科學、學煮飯、跳舞、看電影紀錄片</p>
                    </div>
                    <div className="context">
                      <h6 className="item_title">關心議題</h6>
                      <p>生態環境友善、性別平權、自我成長探索、跨文化(移動)交流、公共藝術的角色、青年社宅</p>
                    </div>
                    <div className="context">
                      <h6 className="item_title">習慣與人分享</h6>
                      <p>自然、花藝、食物、住居、青年社會參與、共同工作、文化、藝術資訊、好友串聯</p>
                    </div>
                  </div>
                  <div className="col_1 col_b">
                    <div className="context">
                      <h6 className="item_title">公共藝術與我的關係</h6>
                      <p>民眾參與<br />與日常生活有關<br />引人思考社會、精神層次的課題</p>
                    </div>
                    <div className="context">
                      <h6 className="item_title">對我來說「家/家園」是什麼</h6>
                      <p>芳鄰、故人、可以改變的能力、可以貢獻的地方</p>
                    </div>
                    <div className="context">
                      <h6 className="item_title">我所關心的：現階段生命順序前幾名是？</h6>
                      <p>1. 自我成長/價值/身心健康<br />2. 穩定且長久的關係<br />3. 家人關係良好<br />4. 財務<br />5. 探索世界、國際對話</p>
                    </div>
                  </div>
                </div>
                <div className="col_person_visual">
                  <img src={require("../images/project_c/c_2/2.png")} alt="Joyce 創作者"/>
                </div>
              </div>

              <div className="persona_item">
                <div className="col_content row_area_ms">
                  <div className="title_group">
                    <h6 className="name">Adair</h6>
                    <h5 className="title">生意人</h5>
                  </div>
                  <div className="col_1 col_a">
                    <div className="context">
                      <h6 className="item_title">相關背景</h6>
                      <p>土生土長木柵人<br />做生意<br />出國留學與工作10年<br />救生員</p>
                    </div>
                    <div className="context">
                      <h6 className="item_title">專長</h6>
                      <p>做生意/買賣、運動訓練、燒結陶瓷、做菜、工程平面設計、社區工作</p>
                    </div>
                    <div className="context">
                      <h6 className="item_title">興趣</h6>
                      <p>賺錢、閱讀、運動、唸佛、組裝模型</p>
                    </div>
                    <div className="context">
                      <h6 className="item_title">關心議題</h6>
                      <p>國際社會運動、運動賽事相關、木柵文化/人文/環境、文化的延續</p>
                    </div>
                    <div className="context">
                      <h6 className="item_title">習慣與人分享</h6>
                      <p>美食餐廳、觀點、社會議題、哪裡可以賺錢、爬山路線、自行車路線</p>
                    </div>
                  </div>
                  <div className="col_1 col_b">
                    <div className="context">
                      <h6 className="item_title">公共藝術與我的關係</h6>
                      <p>有一個景象可以凝視</p>
                    </div>
                    <div className="context">
                      <h6 className="item_title">對我來說「家/家園」是什麼</h6>
                      <p>有媽媽在、老婆在的房子</p>
                    </div>
                    <div className="context">
                      <h6 className="item_title">我所關心的：台灣在哪個部分值得我們去欣賞與了解？</h6>
                      <p>跨世代的溝通、跨海洋的生活、在地文化的重視、與在地生活的連結</p>
                    </div>
                  </div>
                </div>
                <div className="col_person_visual">
                  <img src={require("../images/project_c/c_2/3.png")} alt="Adair 生意人"/>
                </div>
              </div>


              <div className="persona_item">
                <div className="col_content row_area_ms">
                  <div className="title_group">
                    <h6 className="name">Mabel</h6>
                    <h5 className="title">社區工作者</h5>
                  </div>
                  <div className="col_1 col_a">
                    <div className="context">
                      <h6 className="item_title">相關背景</h6>
                      <p>台北人<br/>社區規劃師<br/>台大城鄉所</p>
                    </div>
                    <div className="context">
                      <h6 className="item_title">專長</h6>
                      <p>社區溝通、工作坊、讓人參與活動</p>
                    </div>
                    <div className="context">
                      <h6 className="item_title">興趣</h6>
                      <p>畫畫、旅行、品嘗美食</p>
                    </div>
                    <div className="context">
                      <h6 className="item_title">關心議題</h6>
                      <p>空間行為/公共溝通、生活環境、學習教育</p>
                    </div>
                    <div className="context">
                      <h6 className="item_title">習慣與人分享</h6>
                      <p>萬華的現在/過去/未來、旅行、跟人一起的生活</p>
                    </div>
                  </div>
                  <div className="col_1 col_b">
                    <div className="context">
                      <h6 className="item_title">公共藝術與我的關係</h6>
                      <p>活的社區，才長得出藝術</p>
                    </div>
                    <div className="context">
                      <h6 className="item_title">對我來說「家/家園」是什麼</h6>
                      <p>一起生活的地方</p>
                    </div>
                    <div className="context">
                      <h6 className="item_title">我所關心的：社群網絡對自己的意義?</h6>
                      <p>促成人際連結<br/>日記、寫真的東西</p>
                    </div>
                  </div>
                </div>
                <div className="col_person_visual">
                  <img src={require("../images/project_c/c_2/4.png")} alt="Mabel 社區工作者"/>
                </div>
              </div>

              <div className="persona_item">
                <div className="col_content row_area_ms">
                  <div className="title_group">
                    <h6 className="name">Hedy</h6>
                    <h5 className="title">藝術行政</h5>
                  </div>
                  <div className="col_1 col_a">
                    <div className="context">
                      <h6 className="item_title">相關背景</h6>
                      <p>建築系室內設計組畢業<br />Glasgow School of Art in the UK</p>
                    </div>
                    <div className="context">
                      <h6 className="item_title">專長</h6>
                      <p>烘焙、畫圖、攝影</p>
                    </div>
                    <div className="context">
                      <h6 className="item_title">興趣</h6>
                      <p>烘焙、電影、攝影、書籍</p>
                    </div>
                    <div className="context">
                      <h6 className="item_title">關心議題</h6>
                      <p>空間行為/演化、台灣國際關係、對於當下生活，人生現實的反思、腦科學</p>
                    </div>
                    <div className="context">
                      <h6 className="item_title">習慣與人分享</h6>
                      <p>烘焙、攝影作品、國際時事議題、野生動物小知識、台灣政治議題</p>
                    </div>
                  </div>
                  <div className="col_1 col_b">
                    <div className="context">
                      <h6 className="item_title">公共藝術與我的關係</h6>
                      <p>是否能夠為社會思想帶來改變<br />美感的普及化</p>
                    </div>
                    <div className="context">
                      <h6 className="item_title">對我來說「家/家園」是什麼</h6>
                      <p>Home is a feeling, got a place.</p>
                    </div>
                    <div className="context">
                      <h6 className="item_title">我所關心的：社群網絡對自己的意義?</h6>
                      <p>呈現不真實的自我，同時在某些時刻刻意透漏線索，及價值觀在世代中的混淆。</p>
                    </div>
                  </div>
                </div>
                <div className="col_person_visual">
                  <img src={require("../images/project_c/c_2/5.png")} alt="Hedy 藝術行政"/>
                </div>
              </div>


              <div className="persona_item">
                <div className="col_content row_area_ms">
                  <div className="title_group">
                    <h6 className="name">Ingrid</h6>
                    <h5 className="title">藝術行政</h5>
                  </div>
                  <div className="col_1 col_a">
                    <div className="context">
                      <h6 className="item_title">相關背景</h6>
                      <p>政治經濟<br />美術館藝廊(展覽執行)</p>
                    </div>
                    <div className="context">
                      <h6 className="item_title">專長</h6>
                      <p>行政執行、藝術家研究、排球</p>
                    </div>
                    <div className="context">
                      <h6 className="item_title">興趣</h6>
                      <p>藝術品維護、閱讀、電影、吃吃喝喝</p>
                    </div>
                    <div className="context">
                      <h6 className="item_title">關心議題</h6>
                      <p>政經、藝文展覽方向、環保生態、自我認同</p>
                    </div>
                    <div className="context">
                      <h6 className="item_title">習慣與人分享</h6>
                      <p>美食、展覽、旅遊</p>
                    </div>
                  </div>
                  <div className="col_1 col_b">
                    <div className="context">
                      <h6 className="item_title">公共藝術與我的關係</h6>
                      <p>在生活中最親近人的藝術，為你我增添另一種(地方)想像</p>
                    </div>
                    <div className="context">
                      <h6 className="item_title">對我來說「家/家園」是什麼</h6>
                      <p>能讓你想念，想一直停留的地方。</p>
                    </div>
                    <div className="context">
                      <h6 className="item_title">我所關心的：現階段生命順序前幾名是？</h6>
                      <p>自我成就/探索/定位<br />感情維繫<br />健康<br />財務</p>
                    </div>
                  </div>
                </div>
                <div className="col_person_visual">
                  <img src={require("../images/project_c/c_2/6.png")} alt="Ingrid 藝術行政"/>
                </div>
              </div>

              <div className="persona_item">
                <div className="col_content row_area_ms">
                  <div className="title_group">
                    <h6 className="name">Cade</h6>
                    <h5 className="title">設計師</h5>
                  </div>
                  <div className="col_1 col_a">
                    <div className="context">
                      <h6 className="item_title">相關背景</h6>
                      <p>建築設計<br />旅宿業者<br />在地藝術團體<br />網路經營行銷</p>
                    </div>
                    <div className="context">
                      <h6 className="item_title">專長</h6>
                      <p>空間設計、平面設計、品牌規劃、策展、文字</p>
                    </div>
                    <div className="context">
                      <h6 className="item_title">興趣</h6>
                      <p>畫圖、唱歌、煮飯、攝影、音樂、旅行、寫文章、說笑話</p>
                    </div>
                    <div className="context">
                      <h6 className="item_title">關心議題</h6>
                      <p>都市文化治理、地方文化認同和創新、青年創業(商業設計)、政治、世代、大數據應用</p>
                    </div>
                    <div className="context">
                      <h6 className="item_title">習慣與人分享</h6>
                      <p>食物、政治、藝文資訊、社會觀點、施工、行政等資訊</p>
                    </div>
                  </div>
                  <div className="col_1 col_b">
                    <div className="context">
                      <h6 className="item_title">公共藝術與我的關係</h6>
                      <p>希望能不停在雕塑，新媒體或社區介入帶來一點生活感的新改變。</p>
                    </div>
                    <div className="context">
                      <h6 className="item_title">對我來說「家/家園」是什麼</h6>
                      <p>有歸屬感、安全感的場域，想讓他變好的地方。</p>
                    </div>
                    <div className="context">
                      <h6 className="item_title">我所關心的：台灣在哪個部分值得我們去欣賞與了解？</h6>
                      <p>各地細微的生活方式。</p>
                    </div>
                  </div>
                </div>
                <div className="col_person_visual">
                  <img src={require("../images/project_c/c_2/7.png")} alt="Cade 設計師"/>
                </div>
              </div>


              <div className="persona_item">
                <div className="col_content row_area_ms">
                  <div className="title_group">
                    <h6 className="name">Tess</h6>
                    <h5 className="title">專案企劃</h5>
                  </div>
                  <div className="col_1 col_a">
                    <div className="context">
                      <h6 className="item_title">相關背景</h6>
                      <p>戲劇系畢業<br />社區青年工作隊<br />在地藝術團體</p>
                    </div>
                    <div className="context">
                      <h6 className="item_title">專長</h6>
                      <p>手工藝</p>
                    </div>
                    <div className="context">
                      <h6 className="item_title">興趣</h6>
                      <p>逛書店、看書、騎車亂逛、散步、觀察人事物</p>
                    </div>
                    <div className="context">
                      <h6 className="item_title">關心議題</h6>
                      <p>地方、人文、自我/民族、藝術</p>
                    </div>
                    <div className="context">
                      <h6 className="item_title">習慣與人分享</h6>
                      <p>資訊、觀察、回饋</p>
                    </div>
                  </div>
                  <div className="col_1 col_b">
                    <div className="context">
                      <h6 className="item_title">公共藝術與我的關係</h6>
                      <p>從生活裡來，回到生活裡發生。</p>
                    </div>
                    <div className="context">
                      <h6 className="item_title">對我來說「家/家園」是什麼</h6>
                      <p>有愛你與你愛的人事物所在的地方。</p>
                    </div>
                    <div className="context">
                      <h6 className="item_title">我所關心的：世代如何正確傳承？</h6>
                      <p>適性發展。</p>
                    </div>
                  </div>
                </div>
                <div className="col_person_visual">
                  <img src={require("../images/project_c/c_2/8.png")} alt="Tess 專案企劃"/>
                </div>
              </div>

            </div>
          </section>

        </div>


        <div className="page_footer">
          <Link to="/project_c_3" className="next_page">
            <div className="next_title">民眾共編</div>
            <div className="next_owner">策展人/林書豪</div>
          </Link>
        </div>

      </div>

    );
  }

}


export default Page
